import { helper } from "@/shared/services/helper";

const regexMap = {
    // passwordRegex : /^[A-Za-z0-9]{8,20}$/,
    passwordRegex : /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d\W_]+$/,
    userRegex : /^(?=.*?[A-Za-z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{6,25}$/,
    withdrawPasswordRegex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]+$/,
    referCodeRegex : /^[a-zA-Z0-9]+$/
};

const validate = {
    areFieldsValid(type, prop, prop2, prop3, prop4, prop5, prop6) {
        const errorObj = {};
    
        const addError = (type, error, lang) => {
            if (!errorObj[type]) {
                errorObj[type] = [];
            }
            errorObj[type].push({ error, lang });
        };
    
        const validateMobile = (mobile) => {
            if (mobile.length !== 10) {
                addError('mobile', 'The Mobile number should be of 10 digits', 'phn-10-digit-msg');
            }
        };
        const validatePassword = (password) => {
            if (password.length < 8) {
                addError('password', 'Password Must Be Of Minimum 8 Characters And Maximum 20 Characters', 'pass-min-8-max-20');
            }
            if (!helper.testRegex(regexMap.passwordRegex, password)) {
                addError('password', 'Password Must Contain Alphabets, Numbers, And At Least 1 In Capital Case, And 1 In Lower Case.', 'password-valid-msg');
            }
        };
    
        const checkPasswordMatch = (password, confirmPassword) => {
            if (password !== confirmPassword) {
                addError('confirmPassword', 'Password Mismatch.', 'password-mismatch');
            }
        };
    
            // Withdraw password
        const validateWithdrawPassword = (password) => {
            if (password.length < 8) {
                addError('password', 'Password Must Be Of Minimum 8 Characters And Maximum 20 Characters.', 'pass-min-8-max-20');
            }
            if (!helper.testRegex(regexMap.withdrawPasswordRegex, password)) {
                addError('password', 'Password Must Contain Alphabets, Numbers, special characters And At Least 1 In Capital Case, And 1 In Lower Case.', 'password-valid-msg');
            }
        };
        // Validation for each auth type
        if (type === 'login') {
            const tab = prop, mobile = prop2, userId = prop3, password = prop4;
            if (tab == 1 && mobile === '') {
                addError('mobile', 'Please Enter Mobile Number', 'please-enter-phone-number');
            }
            if (tab == 2 && userId === '') {
                addError('userId', 'Please Enter User ID', 'please-enter-user-name');
            }
            if (password === '') {
                addError('password', 'Please Enter Password', 'please-enter-password');
            }
        }
        
        if (type === 'signup') {
            const mobile = prop, otp = prop2, password = prop3, confirmPassword = prop4, referCode = prop5, userId = prop6;
            validateMobile(mobile);
            if (otp == '' && otp?.length == 0) {
                addError('otp', 'Please Enter OTP', 'please-enter-otp');
            }
            if (otp != '' && otp?.length != 6) {
                addError('otp', 'Please Enter 6 Digit OTP', 'otp-6-digit-msg');
            }
            if (userId && (userId?.length < 6 || userId?.length > 25)) {
                addError('userId', 'User ID Must Be Between 6 To 25 Characters', 'user-id-6-to-25');
            }
            if (password === '') {
                addError('password', 'Please Enter Password', 'please-enter-password');
            } else {
                validatePassword(password);
            }
            if (confirmPassword === '') {
                addError('confirmPassword', 'Please Enter Confirm Password', 'please-enter-confirm-password');
            }
            if (confirmPassword != '') {
                checkPasswordMatch(password, confirmPassword);
            }
        }
    
        if (type === 'forgot-password') {
            const mobile = prop, otp = prop2, password = prop3, confirmPassword = prop4;
            validateMobile(mobile);
            if (otp == '' && otp?.length == 0) {
                addError('otp', 'Please Enter OTP', 'please-enter-otp');
            }
            if (otp != '' && otp?.length != 6) {
                addError('otp', 'Please Enter 6 Digit OTP', 'otp-6-digit-msg');
            }
            if (password === '') {
                addError('password', 'Please Enter Password', 'please-enter-password');
            } else {
                validatePassword(password);
            }
            if (confirmPassword === '') {
                addError('confirmPassword', 'Please Enter Confirm Password', 'please-enter-confirm-password');
            }
            if (confirmPassword !== '') {
                checkPasswordMatch(password, confirmPassword);
            }
        }
         // Withdraw password
         if (type === 'withdraw-password') {
            const otp = prop, password = prop2, confirmPassword = prop3;
            if (otp === '') {
                addError('otp', 'Please Enter OTP', 'please-enter-otp');
            }
            if (otp != '' && otp.length != 6) {
                addError('otp', 'Please Enter 6 Digit OTP', 'please-enter-otp-6');
            }
            validateWithdrawPassword(password);
            if (password !== '' && confirmPassword === '') {
                addError('confirmPassword', 'Please Enter Confirm Password', 'please-enter-confirm-password');
            }
            if (password !== '' && confirmPassword !== '') {
                checkPasswordMatch(password, confirmPassword);
            }
        }
    
        if (type === 'change-password') {
            const oldPassword = prop, newPassword = prop2, confirmPassword = prop3;
            if (oldPassword === '') {
                addError('oldPassword', 'Please Enter Old Password', 'please-enter-old-password');
            }
            if (newPassword === '') {
                addError('password', 'Please Enter New Password', 'please-enter-new-password');
            } else {
                validatePassword(newPassword);
            }
            if (newPassword !== '' && confirmPassword === '') {
                addError('confirmPassword', 'Please Enter Confirm Password', 'confirm-new-password');
            }
            if (confirmPassword !== '') {
                checkPasswordMatch(newPassword, confirmPassword);
            }
        }
    
        return errorObj;
    }
    
    
};
export default validate;