
const AccountModule = () => import(/* webpackChunkName: "account-module" */ './views/Module.vue');
const Profile = () => import( /* webpackChunkName: "profile" */'./views/Profile.vue');
const AccountStatement = () => import(/* webpackChunkName: "account" */ './views/AccountStatement.vue');
const ProfitAndLoss = () => import(/* webpackChunkName: "account-module" */ '../account/views/profitAndLoss.vue');
const setButtonValue = () => import(/* webpackChunkName: "account-module" */ '../account/views/setValue.vue');
const UnsettledBet = () => import(/* webpackChunkName: "account-module" */ '../account/views/UnsettledBet.vue');
const ProfitLossDetail = () => import(/* webpackChunkName: "ProfitLossDetail" */ '../account/views/ProfitLossDetail.vue');
const CasinoWinLossDetails = () => import(/* webpackChunkName: "win-loss" */ '../account/views/CasinoWinLossDetail.vue');


const AccountRoutes = {
    path: '/',
    component: AccountModule,
    children: [
        {
            path: '/profile',
            name: 'Profile',
            component: Profile
        },
        {
            path: '/statement/:type?',
            name: 'AccountStatement',
            component: AccountStatement
        },
        {
            path: '/profit-and-loss',
            name: 'profit-and-loss',
            component: ProfitAndLoss,
        },
        {
            path: '/set-button-value',
            name: 'set-button-value',
            component: setButtonValue,
        },
        {
            path: '/unsettled-bet',
            name: 'unsettled-bet',
            component: UnsettledBet,
        },
        {
            path: '/profit-loss-detail',
            name: 'ProfitLossDetail',
            component: ProfitLossDetail,
        },
        {
            path: 'casino-win-loss-details',
            name: 'casino-win-loss-details',
            component: CasinoWinLossDetails,
        }

    ],
}

export default AccountRoutes;