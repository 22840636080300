import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

import auth from '@/modules/authorization/store'
import accountStore from '@/modules/account/store'
import setmenu from '@/modules/matka/store';
import commonStore from '@/shared/store'

const mutations = {
  resetState(state) {
    state.auth.user = null;
    state.auth.token = null;
    state.auth = {
      user: null,
      chips: null,
      token: null,
      eventManage: null,
      eventDetailHideSections: []
    }
    state.commonStore.siteVersion = null;
  },
 
}
const actions = {
  resetAllState({ commit }) {
    commit('resetState')
  }
}

export default createStore({
  state: {},
  mutations,
  actions,
  modules: { auth,setmenu, commonStore, accountStore },
  plugins: [createPersistedState({
    key:'sd:site'
  })]
});