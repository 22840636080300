import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const dateTime = {
    sportItemDate: (date, format = 'D MMM, HH:mm') => {
        return dayjs(date).format(format);
    },
    convertUnixDate: (date, format = 'DD MMM YYYY HH:mm:ss') => {
        return dayjs.unix(date).format(format)
    },
    currentTimestamp: () => {
        return dayjs().unix();
    },
    getcurrentDate: (date = null, format = 'YYYY-MM-DD') => {
        return dayjs(new Date()).format(format);
    },
    getDateSaperatedByDays: (days, date = new Date(), format = 'YYYY-MM-DD' ) => {
        return dayjs(date).subtract(days, 'day').format(format);
    },
    convertOnlyTime: (time) =>{
        return dayjs(time, "HH:mm").format("HH:mm");
    },
    convertUtcDate: (date) => {
        return dayjs.utc(date);
    },
    getPreviousDate: (day = 1, format = 'YYYY-MM-DD') => {
        return dayjs().subtract(day, 'day').format(format);
    },
     // Wallet
     getUTCTime: (format = 'HH:mm:ss') =>{
        return dayjs.utc().format(format)
    },
    NotInplaySportItemDateOnly(date, format = 'DD MMM') {
        if(dayjs(date).isSame(dayjs(), 'day')) return 'Today'
        else return this.sportItemDate(date, format)
    },
    getLocalDate:(date, format = 'DD MMM HH:mm') => {
        const localDate = dayjs.utc(date).local().format(format);
        return localDate;
    },
} 

export default dateTime