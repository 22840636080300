<template>
    <section class="login-now" loding="lazy">
        <div class="wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="loginInner1">
                            <div class="log-logo">
                                <img :src="$siteSettings?.data?.aws_url + $siteSettings?.data?.storage_path.domain_image + $siteSettings?.data?.logo" alt="logo" class="logo-login">
                            </div>
                            <div class="featured-box-login">
                                <!-- <h1>{{ $t('register-account','Register Account')}} <i class="fas fa-hand-point-down"></i></h1> -->
                                <p class="whats-with"
                                    v-if="$siteSettings?.data?.social_link && $siteSettings?.data?.social_link.whatsappno1">
                                    <span class="whats-with-now">{{ $t('get-your-ready-made-id-from-whatsapp',"Get your ready-made ID from whatsapp")}}</span>
                                </p>
                                <form class="form-control-head">
                                    <div class="whats-app"
                                        v-if="$siteSettings?.data?.social_link && $siteSettings?.data?.social_link.whatsappno1">
                                        <div class="button-whatsapp">
                                            <button class="btn-whatsapp" @click.prevent="openWhatsApp()">
                                                <img src="@/assets/images/whatsapp.webp" alt="" />
                                                <span class="blinking">{{ $t('whatsapp-now',"WhatsApp Now")}}</span></button>
                                        </div>
                                        <h3 class="whats-with">
                                            <span class="whats-with-now">
                                                {{ $t('or-create-account-with-number',"OR create account with Number")}}
                                            </span>
                                        </h3>
                                    </div>

                                    <!-- <div role="alert" class="p-2 alert alert-danger"
                                        v-if="showValidationError && errorList.length">
                                        <ul>
                                            <li class="py-1" v-for="(error, index) in errorList" :key="index">&#x2022; {{ error }}</li>
                                        </ul>
                                    </div> -->
                                    <div class="form-group">
                                        <div class="get-mob">
                                            <div class="row">
                                                <div class="col-2">
                                                    <div class="col-auto">
                                                        <select v-model="form.code" class="form-select"
                                                            aria-label="Default select example" :disabled="timerRunning">
                                                            <option :value="allowedCountry.phonecode"
                                                                v-for="(allowedCountry, countryIndex) in $siteSettings?.data?.country_code_list"
                                                                :key="countryIndex" >+{{ allowedCountry.phonecode }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-10">
                                                    <div class="col-auto">
                                                        <input type="tel" class="form-control add" id="autoSizingInput" @input="form.mobileNo = $helper.sanitizeNumber(form.mobileNo,9999999999); validate()"
                                                        :placeholder="$t('enter-mobile-number','Enter Mobile Number')+'*'" maxlength="10" v-model="form.mobileNo" :disabled="resendOtp && timerRunning" required/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mak-gin mo-number-right get_otp">
                                                <!-- <p>{{ form.mobileNo.toString().length }}/10</p> -->
                                                <button class="btn cmn-bdr-btn otp-btn" :disabled=" timerRunning"  @click.prevent="sendOtpCall()"
                                                    >{{ timerRunning ? formatTime(minutes) + ":" + formatTime(seconds) : resendOtp ? $t('resend-otp',"Resend OTP") : $t('get-otp',"Get OTP") }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="init.mobile && checkValidation?.mobile?.length > 0" class="mt-2">
                                        <span v-for="(err, index) in checkValidation?.mobile" :key="index">
                                            <p class="wrong-message">{{ $t(err.lang, err.error) }}</p>
                                        </span>
                                    </div>
                                    <div  v-if="init.mobile && form.mobileNo.length == 10 && !isOtpApiCalled">
                                        <p class="wrong-message">{{$t('please-click-on-send-otp','Please Click on Get OTP')}}</p>
                                    </div>
                                    <div class="otp-form-group">
                                        <div class="get-mob for mb-0" v-if="isOtpApiCalled">
                                            <input type="tel" class="form-control" id="inputPassword2" @input="form.otp = $helper.sanitizeNumber(form.otp, 999999); validate()"
                                            :placeholder="$t('enter-otp','Enter OTP')+'*'" v-model="form.otp" maxlength="6" />
                                            <div class="inp-img"><img src="@/assets/images/key-icon.webp" alt="" /></div>
                                        </div>
                                        <div v-if="init.otp && checkValidation?.otp?.length > 0">
                                            <p v-for="(err, index) in checkValidation?.otp" :key="index" class="wrong-message mt-1">
                                                {{ $t(err.lang, err.error) }}</p>
                                        </div>
                                        <!-- <div v-if="resendOtp">
                                            <span id="resend_time" v-if="timerRunning" class="otp-countdown-text">
                                                {{ $t('resend-otp-in',"Resend OTP in")}} {{ formatTime(minutes) + ':' + formatTime(seconds) }}
                                                <span id="timer"></span></span>
                                            <span id="resend_button" class="otp-countdown-text" v-else>
                                                <a href="Javascript:void(0);" class="text-success resend-btn" type="button"
                                                    @click.prevent="sendOtpCall()">
                                                    {{ $t('send-sms-again',"SEND SMS AGAIN")}}
                                                </a>
                                            </span>
                                        </div> -->
                                    </div>
                                    <div class="form-group">
                                        <div class="refer-code-hyper" v-if ="!isSetUserId && !hasUrlReferer">
                                            <a href="javascript:void(0)" @click="isSetUserId =! isSetUserId">{{$t('set-user-id','Want to set UserID')}}?</a>
                                        </div>
                                        <div class="get-mob for left_user_field" v-if ="isSetUserId || hasUrlReferer">
                                            <input type="test" maxlength="25" class="form-control" id="inputPassword2"  @input="form.userName = $helper.sanitizeDetails('userId', form.userName); validate()"
                                            :placeholder="$t('enter-user-id','Enter User ID')+''" v-model="form.userName"/>
                                            <div class="inp-img"><img src="@/assets/images/user-login.webp" alt="" /></div>
                                        </div>

                                        <div v-if="isOtpApiCalled && init.userId && checkValidation?.userId?.length > 0" class="mt-2">
                                            <span v-for="(err, index) in checkValidation?.userId" :key="index">
                                                <p class="wrong-message">{{ $t(err.lang, err.error) }}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="get-mob for left_password_field">
                                            <input :type="passwordFieldType" maxlength="20" class="form-control" id="inputPassword4" @input="form.password = $helper.sanitizeDetails('password', form.password, 20); validate()"
                                            :placeholder="$t('enter-password','Enter Password')+'*'" v-model="form.password" :disabled="!isOtpApiCalled"/>
                                            <div class="key-img"><img src="@/assets/images/key-icon.svg" alt="" /></div>
                                            <div class="inp-img" @click="togglePasswordVisibility('password')">
                                                <img :src="passwordFieldType === 'password' ? require('@/assets/images/passwordhideicon.webp') : require('@/assets/images/passwordviewicon.webp')" alt=""/>
                                                <!-- <img v-else src="@/assets/images/passwordviewicon.webp" alt=""/> -->
                                            </div>
                                        </div>
                                        <div v-if="isOtpApiCalled && init.password && checkValidation?.password?.length > 0" class="mt-2">
                                            <span v-for="(err, index) in checkValidation?.password" :key="index">
                                                <p class="wrong-message">{{ $t(err.lang, err.error) }}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="get-mob for left_password_field">
                                            <input :type="confPasswordFieldType" maxlength="20" class="form-control" id="inputPassword5" @input="form.confirmPassword = $helper.sanitizeDetails('password', form.confirmPassword, 20); validate()"
                                            :placeholder="$t('enter-confirm-password','Enter Confirm Password')+'*'" v-model="form.confirmPassword" :disabled="!isOtpApiCalled"/>
                                            <div class="key-img"><img src="@/assets/images/key-icon.svg" alt="" /></div>
                                            <div class="inp-img" @click="togglePasswordVisibility('confpassword')">
                                                <img :src="confPasswordFieldType === 'password' ? require('@/assets/images/passwordhideicon.webp') : require('@/assets/images/passwordviewicon.webp')" alt=""/>
                                                <!-- <img v-else src="@/assets/images/passwordviewicon.webp" alt=""/> -->
                                            </div>
                                        </div>
                                        <div v-if="isOtpApiCalled && init.confirmPassword && checkValidation?.confirmPassword?.length > 0" class="mt-2">
                                            <span v-for="(err, index) in checkValidation?.confirmPassword" :key="index">
                                                <p class="wrong-message">{{ $t(err.lang, err.error) }}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="refer-code-hyper" v-if ="!isRefral && !hasUrlReferer">
                                            <a href="javascript:void(0)" @click="isRefral =! isRefral">{{$t('have-a-refferal-code','Have a referral code')}}?</a>
                                        </div>
                                        <div class="get-mob for left_user_field" v-if ="isRefral || hasUrlReferer">
                                            <div>
                                                <input type="text" class="form-control refer-code" @input="form.referCode = $helper.sanitizeDetails('referCode', form.referCode); validate()" :disabled="getQueryParam('refer_code', window?.location?.href)"
                                                    :placeholder="$t('enter-refer-code','Enter Refer code')" v-model="form.referCode" />
                                                <div class="inp-img"><img src="@/assets/images/signup-refer.webp" alt="" /></div>
                                                <div v-if="init && checkValidation?.referCode?.length > 0" class="mt-2">
                                                    <span v-for="(err, index) in checkValidation?.referCode" :key="index">
                                                        <p class="wrong-message">{{ $t(err.lang, err.error) }}</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <!-- <a href="JAvascript:void(0);" class="btn btn-submit btn-login" :class="!isFormValid ? 'disabled':''" -->
                                        <a href="JAvascript:void(0);" class="btn btn-submit btn-login" @click="signUpCall()">
                                            {{ $t('register','Register')}}
                                            <i class="ml-2 fas fa-sign-in-alt"></i>
                                            <span v-if="loadingSignUp"> 
                                                <span class="load-inner"><i class="fa fa-spinner fa-spin mt-0"></i></span>
                                            </span>
                                        </a>
                                    </div>                                    

                                    <p class="whats-with-acc">{{ $t('already-have-an-account','Already have an account?')}} &nbsp;
                                        <router-link v-if="isRootUrl" @click="refreshData" :to="{ name: 'Login' }">
                                            {{ $t('log-in','Login')}}
                                        </router-link>
                                        <a v-else ref="loginModal" data-bs-toggle="modal" @click="refreshData" data-bs-target="#loginModal">
                                            {{ $t('log-in','Login')}}
                                        </a>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import commonAuth from '../services/commonAuth';
import validate from '../services/authValidation';
export default {
    name: "SignUp",
    extends: commonAuth,
    data() {
        return {
            form: {
                code: this.$siteSettings?.data && this.$siteSettings?.data.cncode ? this.siteSettings.cncode : "91",
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
            },
            loadingSignUp: false,
            isRefral:false,
            hasUrlReferer: false,
            validationErrors : null,

        };
    },
    created() {
        if(this.$siteSettings?.data && this.$siteSettings?.data?.business_type == 1){
            this.$router.push({ name: 'Login' })
        }
        if((window.location.href).includes('refer_code')) {
            this.hasUrlReferer = true;
            this.isRefral = true;
        }  
    },
    computed: {
        isRootUrl() {
            return this.$route.path === '/login' || this.$route.path === '/forgot-password' || this.$route.path === '/register';
        },    
        checkValidation() {
            return this.validationErrors;
        }
    },
    methods: {
        togglePasswordVisibility(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            } else {
                this.confPasswordFieldType = this.confPasswordFieldType === 'password' ? 'text' : 'password';
            }
            
        },
        openWhatsApp() {
            window.open(`//wa.me/${this.$siteSettings?.data.social_link.whatsappno1}`, '_blank');
        },
        signUpCall() {
            if (this.loadingSignUp) {
                return;    
            }
            this.toggleInitPhn(true);
            if(this.isOtpApiCalled) {
                this.toggleInit(true);
            }
            if(Object.keys(this.validate())?.filter(key=> key !='referCode').length != 0){
                return;
            }
            if (!this.isOtpApiCalled) {
                this.$errorToast('Call for OTP.');
                return;
            } 
            else {
                this.signUpServiceCall();
            }
        },        
        async signUpServiceCall() {
            this.loadingSignUp = true;     
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let data = {
                "userid": this.form.userName ? this.form.userName : null,
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "cncode": this.form.code,
            };
            this.$apiCall('post', 'REGISTER', data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.$errorToast(response.data.message);
                        } else {
                            this.$successToast(response.data.message);
                            this.refreshData();
                            // this.$router.push({name: 'Login'})
                            this.$refs.loginModal.click()

                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                if (error)
                    this.$errorToast(error[0]);
            });
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
        validate(){
            this.validationErrors = validate.areFieldsValid(
                'signup',
                this.form.mobileNo,
                this.form.otp,
                this.form.password,
                this.form.confirmPassword,
                this.form.referCode,
                this.form.userName,
            );
            return this.validationErrors;
        },
        toggleInitPhn(bool){
            this.init={
                mobile:bool
            }
        },
        toggleInit(bool){
            this.init={
                otp:bool,
                password:bool,
                confirmPassword:bool,
                userId:bool

            }
        },
    },
};
</script>