<template>
	<div v-if="onLine && isSiteStatusCalled">
		<template
			v-if="$route.name == 'Login' || $route.name == 'SignUp' || $route.name == 'ForgetPassword'">
			<router-view v-if="$siteSettings?.data"></router-view>
		</template>

		<template v-else>
			<template v-if="!underMaintenance && $route.name != 'NotFound' && $route.name != 'Maintenance'">
				<Header v-if="checkForHeaderShow()"></Header>
				<SportSelection></SportSelection>
			</template>

			<router-view v-if="checkRoute()"></router-view>

			<section class="inner-page-sec" loading="lazy" v-else>
				<div class="main">
					<div class="container-fluid">
						<div class="row">
							<router-view></router-view>
						</div>
					</div>
				</div>
			</section>
			<Footer :routePath="routePath"></Footer>
		</template>
	</div>

	<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications"/>	

	<div v-if="successMsg" class="toster-wrapper" ref="successmodal" id="successfullyModal">
		<SuccessModal :message="successMsg" />
	</div>

	<div v-if="errorMsg" class="toster-wrapper" id="wrongtoaster">
		<ErrorModal :message="errorMsg" />
	</div>

	<div id="load" v-if="globalLoader || !isSiteStatusCalled">
        <div id="load-inner"><i class="fa fa-spinner fa-spin"></i></div>
    </div>
	<CopyUserIdModal v-if="$auth.status" />
	<Offers></Offers>
</template>

<script>

import { defineAsyncComponent } from 'vue';
import { setHeaders } from '@/shared/services/headers';
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import { mapGetters } from 'vuex';
import axios from "axios";
import socketService from "./socket/sport-socket-service";

export default {
	name: "App",
	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			refreshInit: false,
			scY: 0,
			scTimer: 0,
			screenSize: window.innerWidth,
			mobileView: 1023,
			isSiteStatusCalled: false
		}
	},	
	components: {
		NotificationAlert: defineAsyncComponent(() =>
            import('./shared/components/modal/NotificationAlert.vue')
        ),
		Header: defineAsyncComponent(() =>
            import('./shared/components/header/Header.vue')
        ),
		Footer: defineAsyncComponent(() =>
            import('./shared/components/footer/Footer.vue')
        ),
		ErrorModal: defineAsyncComponent(() =>
            import('./shared/components/modal/ErrorModal.vue')
        ),
		SuccessModal: defineAsyncComponent(() =>
            import('./shared/components/modal/SuccessModal.vue')
        ),
		Maintenance: defineAsyncComponent(() =>
            import('./shared/components/maintenance/Maintainence.vue')
        ),
		SportSelection: defineAsyncComponent(() =>
            import('@/shared/components/sport-selection/SportSelection.vue')
        ),
		CopyUserIdModal: defineAsyncComponent(() =>
			import('./shared/components/modal/CopyUserIdModal.vue')
		),
		Offers: defineAsyncComponent(() =>
			import('./shared/components/header/Offers.vue')
		),
	},
	computed: {
		...mapGetters(['eventDetailHideSections', 'siteSettings', 'successMsg', 'errorMsg', 'siteVersion', 'underMaintenance', 'isUserLoggedIn','globalLoader']),
		routePath() {
			return this.$route.name
		},
		isDemo(){
            return localStorage.getItem('isDemo')
        },
	},
	mounted() {
		this.getSiteVersion();
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
		axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		setHeaders();
		this.$errorToast(null);
		this.$successToast(null);
		
		if (this.$auth?.status) {
			this.getFavourites();
			if(this.$store.getters.stateUser.notificationUnsubscribed==1){
				return;
			}
			if(this.$store.getters.stateUser.notificationSubscribed!=1) {
				this.requestNotificationPermission()
			}
		}
		//checking internet connection
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		window.addEventListener('scroll', this.onScroll);
		this.socketAllEvents();
		this.$errorToast(null);
		this.$successToast(null);
	},
	methods: {
		requestNotificationPermission() {
			if (!('Notification' in window)) {
				console.log('This browser does not support notifications.');
				return;
			}

			if (Notification.permission === 'granted') {				
				this.getSubscriptionData();
			} else if (Notification.permission !== 'denied') {
				this.openNotificationBox()
			}
		},
		openNotificationBox(){
			setTimeout(() => {
                this.$helper.openModal('#notification-alert');
            }, 500);
		},
		allowNotifications(){
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					this.getSubscriptionData();
				}
			});
		},
		cancelNotifications(){
			let user=this.$store.getters.stateUser;
			user.notificationUnsubscribed=1;
			this.$store.dispatch('setUser', user);
		},
		async getSubscriptionData(){
			let user=this.$store.getters.stateUser;
			user.notificationSubscribed=1;
			this.$store.dispatch('setUser', user);
			if ('serviceWorker' in navigator) {
				try {
					await navigator.serviceWorker.register('/service-worker.js').then(async registration => {

						setTimeout(async () => {
							if ('PushManager' in window) {
								if (registration.pushManager) {
									// Subscribe to push notifications
									const subscription = await registration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
									});
									this.deviceData=subscription
									this.subscribeNotifications()
								} else {
									console.warn('PushManager is not available');
								}
							} else {
								console.warn('Push notifications are not supported');
							}
						}, 1000)

					}).catch(e => {
						console.log(e);
					});
				} catch (error) {
					console.error('Service Worker registration failed:', error);
				}
			} else {
				console.warn('Service workers are not supported in this browser.');
			}
		},
		subscribeNotifications(){
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			let data = {
					"deviceData": this.deviceData,
					"domainName": window.location.host,
					};
					this.$apiCall('post', 'SUBSCRIBE_NOTIFICATION', data, 'notification').then(response => {
					this.loadingSignUp = false;
					if (response) {
						console.log(response)
					}
				}).catch(error => {
					this.loadingSignUp = false;
					if (error)
						this.$errorToast(error[0]);
				});
		},
		checkRoute() {
			let routeName = this.$route.name;
			let routeCollection = [
				'Wallet',
				'deposit-payment-method',
				'withdrawal-details',
				'add-account',
				'wallet-history',
				'wallet-history-detail',
				'my-id',
				'bonus'
			]

			return routeCollection.includes(routeName)

		},
		getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
		async getSiteVersion() {
			this.$store.dispatch('setGlobalLoader', true);
			this.$apiCall('get', 'GET_SITE_VERSION', null, 'user', { domain: window.location.hostname }).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {
						let resData = response?.data?.data;
						let currentVersion = resData.version;
						let userSystemVersion = this.siteVersion || 0;		
						if (!userSystemVersion || currentVersion != userSystemVersion || !this.$siteSettings.data) {
							this.$store.dispatch('setSiteVersion', currentVersion);
							this.isSiteStatusCalled = false;
							this.getSiteSettingsServiceCall();
						} else {
							this.siteSettingCommon(this.$siteSettings.data);
						}
						if (resData.maintenance) {
							const { from_date, to_date } = resData.maintenance;
							const fromDateTime = new Date(from_date).getTime();
							const toDateTime = new Date(to_date).getTime();
							const currentDateTime = Date.now();

							const isInMaintenanceWindow = fromDateTime <= currentDateTime && currentDateTime <= toDateTime;

							if (fromDateTime && toDateTime && isInMaintenanceWindow) {
								this.$store.dispatch('setUnderMaintenance', resData.maintenance);
								this.$router.push({ name: 'Maintenance' });
							}
						}
						else {
							if(this.underMaintenance) {
								this.$router.push({name:'Home'});
							}
							this.$store.dispatch('setUnderMaintenance', null); 
						}
					}
					this.$store.dispatch('setGlobalLoader', false);
				}
			}).catch(error => {
				if (error) {
					this.$store.dispatch('setGlobalLoader', false);
					this.$errorToast(error[0]);
				}
			});
		},
		async getSiteSettingsServiceCall() {
			this.$apiCall('get', 'GET_SITE_SETTINGS', null, 'user', { domain: window.location.hostname }).then(async response => {
				this.$store.dispatch('setGlobalLoader', false);
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {
						let resData = response?.data.data.site_settings
						let casino;
						if(response?.data.data.casino_url) {
							const getCasinoGame = async () => {
							let gameResp = await axios.get(response?.data.data.casino_url)
							return gameResp.data
							};
							casino = await getCasinoGame();
						} else {
							casino = response?.data.data?.casino
						}					
						this.$store.dispatch('setSettings', resData);
						this.$store.dispatch('setGamesData', casino);
						this.siteSettingCommon(resData);						
					}
				}
			}).catch(error => {
				this.$store.dispatch('setGlobalLoader', false);
				if (error) {
					this.$errorToast(error);
				}
			});
		},
		async siteSettingCommon(siteSetting) {
			try {
				if (siteSetting.language_translate_list) {
					this.$setCurrentLangData(siteSetting.language_translate_list, siteSetting.language_code, siteSetting.aws_url)
					this.$store.dispatch('setLanguagesData', siteSetting.language_translate_list);
				}
				this.setManifest(siteSetting);
				// Create FAVICON
				const favElement = document.createElement('link');
				favElement.rel = 'icon';
				favElement.href = siteSetting.aws_url + siteSetting.storage_path.domain_image + siteSetting.fav_logo;
				document.head.insertAdjacentElement('beforeend', favElement);

				const title = document.getElementById("title");
				title.text = siteSetting.domain_name;
				let is_offer = localStorage.getItem("is_offer");
				if(siteSetting.meta_tags) {
					document.head.innerHTML += siteSetting.meta_tags;
				};
				if(siteSetting.g_tags) {
					this.setGoogleAnalyticsTag(siteSetting.g_tags);
				};
				if(siteSetting.site_map) {
					const sitemapLink = document.createElement("link");
					sitemapLink.rel = "sitemap";
					sitemapLink.type = "application/xml";
					sitemapLink.title = "Sitemap";
					sitemapLink.href = siteSetting.aws_url + siteSetting.storage_path.site_map + siteSetting.site_map;
					document.head.appendChild(sitemapLink);
				};
				try {
					if (siteSetting.front_css != null && siteSetting.front_css != '') {
						const styleElement = document.createElement('style');
						styleElement.type = 'text/css';
						styleElement.appendChild(document.createTextNode(siteSetting.front_css));
						document.head.insertAdjacentElement('beforeend', styleElement);
					}
				}
				catch (error) {
					console.log("ERROR 1: ", error);
				}
				if (this.$auth?.status){
					this.getWalletBalance();
				}
				let userId = this.$auth?.user?.userid
				if (this.$auth?.status && localStorage.getItem('login_type') == 1 && !this.isUserLoggedIn(userId) && !this.isDemo && this.$auth.user.last_login_at) {
					this.$store.dispatch('setloggedInUserIds', userId);
					setTimeout(() => {
						this.$helper.openModal('#useridmodal')
					}, 500);
				} else{
					if (this.$auth?.status && siteSetting.offer != null && is_offer == null && this.$siteSettings?.data?.business_type == 2 && this.$auth.user.last_login_at) {
						this.$helper.openModal('#offer_pop_up')
					}
				}  
				if(!this.$auth?.status) {
					this.setRoute();
				}
				this.isSiteStatusCalled = true;
			} catch (error) {
				this.$errorToast(error);
			}
		},
		setGoogleAnalyticsTag(gTag) {
			let script1 = document.createElement('script');
			script1.async = true;
			script1.src = `https://www.googletagmanager.com/gtag/js?id=${gTag}`;

			let script2 = document.createElement('script');
			script2.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', '${gTag}');
			`;

			document.head.appendChild(script1);
			document.head.appendChild(script2);
		},
		setRoute() {
			const routeName = this.$route.name;
			const authRoutes = ['SignUp', 'Login', 'ForgetPassword','NotFound']
			if(this.$siteSettings?.data && this.$siteSettings?.data?.business_type == 1) {
				this.$router.push({name: 'Login'})
			}
		},
		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
		getWalletBalance() {
			let authToken = this.$cookies.get(AUTH_TOKEN);
			if (!authToken) return;
			this.$apiCall('post', 'WALLET_BALANCE').then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.expl = response.data.ex;
					user.bets_count = response.data.bc;
					user.earnBonus = response.data.eb;
					this.$store.dispatch('setUser', user);
					if ((user.exp - this.$dateTime.currentTimestamp()) < 10) {
						this.refreshAuthToken(headers);
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error);
				}
			});
		},
		refreshAuthToken(headers) {
			this.refreshInit = true;
			this.$apiCall('post', 'AUTH_REFRESH').then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
				this.refreshInit = false;
			});
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		socketAllEvents() {
			if (this.$store.getters.stateUser) {
				socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type == "myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.$successToast('New Notification...')
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						// 	this.$successToast('New Notification...')
						// }
					}
				});
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					} else if (data.type == 'league_ban') {
						// league ban condition
					}
				});

			}
		},
		checkForHeaderShow() {
			 if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.$route.name == 'SportsEventDetail') {
				return false;
			} else {
				return true;
			}
		},
		getFavourites() {
			let data = {
				"game_type": 1
			}
			this.$apiCall('post', 'GET_FAVOURITE_LIST', data).then(response => {
				if (response) {
					if (response.status == 200) {
						if (response?.data?.status != 0) {
							var allData = response.data.data;
							var ids = [];
							for (var i = 0; i < allData.length; i++) {
								ids.push(allData[i].match_id)
							}
							this.$store.dispatch('setFavouriteGames', ids)
						}
					}
				}
			}).catch(error => {
				if (error) {
				}
			});
		},
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}
};
</script>

<style></style>
