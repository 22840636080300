import store from './store';
import { reactive, watchEffect } from 'vue';
import { apiCall } from '@/shared/services/apiCall' // IMPORT GLOBAL API INSTANCE
import { successToast, errorToast, helper } from '@/shared/services/helper.js' // IMPORT GLOBAL TOASTER
import dataTime from '@/shared/services/dateTime.js'; // IMPORT GLOBAL DATE FUNCTIONS
import { setCurrentLangData, translatedData } from '@/shared/services/languageService'; // IMPORT LANGUAGE SINGLETON
const siteSettings = reactive({ data : store?.getters?.siteSettings }); // IMPORT SITESETTINGS FORM STORE
const auth = reactive({ status: store.getters.isAuthenticated, user: store.getters.stateUser }); // IMPORT AUTH FORM STORE AND MAKING IT REACTIVE

watchEffect(
    () => {
        auth.status = store.getters.isAuthenticated;
        auth.user = store.getters.stateUser;
        siteSettings.data = store?.getters?.siteSettings;
    }
);


const globalProperties = {
    install(app) {
        app.config.globalProperties = {
            $apiCall: apiCall,  // MAKING GLOBAL API INSTANCE
            $successToast: successToast, // MAKING GLOBAL SUCCESS TOAST VARIABLE
            $errorToast: errorToast, // MAKING GLOBAL ERROR TOAST VARIABLE
            $setCurrentLangData: setCurrentLangData, // FUNCTION FOR UPDATING CURRENT LANGUAGE
            $t: translatedData, // FUNCTION FOR GETTING TRANSLATED KEYS
            $auth: auth, // MAKING GLOBAL AUTH VARIABLE
            $helper: helper, // MAKING GLOBAL HELPER FUNCTION
            $siteSettings: siteSettings, // MAKING GLOBAL SITESETTINGS VARIABLE
            $dateTime: dataTime, // MAKING GLOBAL DATE VARIABLE
        }
    }
};

export default globalProperties;