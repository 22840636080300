const state = {
    profitLossItem: null,
    viewBetsItem: null,
    userBonus: null
};
const getters = {
    profitLossItem: state => state?.profitLossItem,
    viewBetsItem: state => state?.viewBetsItem,
    userBonus: state => state?.userBonus
};
const actions = {
    setProfitLossItem({ commit }, profitLossItem) {
        commit('setProfitLossItem', profitLossItem);
    },
    setViewBetsItem({ commit }, viewBetsItem) {
        commit('setViewBetsItem', viewBetsItem);
    },
    setUserBonus({ commit }, userBonus) {
        commit('setUserBonus', userBonus);
    }
};
const mutations = {
    setProfitLossItem(state, profitLossItem) {
        state.profitLossItem = profitLossItem;
    },
    setViewBetsItem(state, viewBetsItem) {
        state.viewBetsItem = viewBetsItem;
    },
    setUserBonus(state, userBonus) {
        state.userBonus = userBonus
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};