

const WalletModule = () => import(/* webpackChunkName: "wallet-module" */ './views/Module.vue');
const Wallet = () => import(/* webpackChunkName: "account" */ './views/Wallet.vue'); 
const DepositAmount = () => import(/* webpackChunkName: "account" */ './views/DepositAmount.vue'); 
const DepositChooseAmount = () => import(/* webpackChunkName: "account" */ '../wallet/views/DepositchoosePaymentmethod.vue'); 
const WithdrawalAmount = () => import(/* webpackChunkName: "account" */ '../wallet/views/Withdrawal.vue'); 
const WalletHistory = () => import(/* webpackChunkName: "account" */ './components/WalletHistory.vue');
const WalletHistoryDetail = () => import(/* webpackChunkName: "wallet-history-detail" */ './views/WalletHistoryDetail.vue');
const WalletGateway = () => import(/* webpackChunkName: "wallet-gateway" */ './views/WalletGateway.vue');

const walletRoutes =   {
    path: '/',
    component: WalletModule,
    children: [
     
      {
        path: '/wallet',
        name: 'Wallet',
        component: Wallet,
      },
      {
        path: '/wallet-deposit',
        name: 'wallet-deposit',
        component: DepositAmount,
      },
      {
        path: '/deposit-payment-method',
        name: 'deposit-payment-method',
        component: DepositChooseAmount,
      },
      {
        path: '/wallet-withdraw',
        name: 'wallet-withdraw',
        component: WithdrawalAmount,
      },
      {
        path: '/wallet-history',
        name: 'wallet-history',
        component: WalletHistory,
      },
      {
        path: 'wallet-history-detail/:type',
        name: 'wallet-history-detail',
        component: WalletHistoryDetail,
      },
      {
        path: 'wallet-gateway',
        name: 'wallet-gateway',
        component: WalletGateway,
      }
      
    ],
  }

export default walletRoutes;