export default {
    data() {
        return {
            isOtpApiCalled: false,
            otpSent: false, // Added variable to track OTP sent status
            isDisabled: true,
            resendOtp: false,
            timerRunning: false,
            minutes: 5,
            seconds: 0,
            passwordFieldType: 'password',
            confPasswordFieldType: 'password',
            init: false,
            phnInit: false,
            otpBtnInit: false,
            isSetUserId: false,
            isRefral: false,
            loginMethod: 'password',

        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: this.$siteSettings.data && this.$siteSettings.data.cncode ? this.$siteSettings.data.cncode : "91",
                mobileNo: "",
                otp: '',
                userName: '',
                password: "",
                confirmPassword: "",
                type: this.$siteSettings?.data?.business_type == 2 ? 1 : 2,

            },
                this.isOtpApiCalled = false;
                this.resendOtp = false;
                this.resendOtpTimer = 0;
                this.timerRunning = false;
                this.minutes = 0;
                this.seconds = 0;
                this.otpSent = false;
                this.passwordFieldType = 'password';
                this.confPasswordFieldType = 'password';
                this.init = false;
                this.showValidationError = false;
                this.phnInit=false;
                this.otpBtnInit=false;
                this.isSetUserId= false;
                this.isRefral= false;
                this.loginMethod= 'password'

        },
        sendOtpCall() {
            if (!this.form.mobileNo) {
                this.$errorToast('Please Enter Mobile Number.');
                return false;
            } else if (this.form.mobileNo.length != 10) {
                this.$errorToast('Number Must be 10 digit.');
                return false;
            }
            this.sendOtpServiceCall();
        },
        sendOtpServiceCall() {
            this.$apiCall('post', 'SEND_OTP', { mobile_number: this.form.mobileNo, cncode: this.form.code }).then(response => {
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.otpSent = true; // OTP sent successfully, disable phone number field
                        this.isDisabled = false;
                        this.$successToast(response.data.message)
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 5;
                        this.seconds = 0;
                        this.setResendTimer();
                    }
                }
            }).catch(error => {
                if (error)
                    this.$errorToast(error[0])
            });
        },
        getOtp() {
            return this.form.otp.toString();
        },
        togglePasswordVisibility(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            } else {
                this.confPasswordFieldType = this.confPasswordFieldType === 'password' ? 'text' : 'password';
            }
        },
        setResendTimer() {

            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
    }
}