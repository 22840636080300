
import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/Home.vue");
const MaintenanceComponent = () => import("@/shared/components/maintenance/Maintainence.vue");
import CricketFightRoutes from "@/modules/cricket-fight";
// import FavouriteRoutes from "@/modules/favourites";
import SportsRoutes from "@/modules/sports";
import GamesRoutes from "@/modules/games";
import matkaRoutes from "@/modules/matka"
// import WalletRoutes from "@/modules/wallet";
import AccountRoutes from "@/modules/account";
import walletRoutes from "@/modules/wallet";
import BonusRoutes from "@/modules/bonus";
import ReferAndEarnRoutes from "@/modules/refer-and-earn";
import BetsRoutes from "@/modules/bets";
import AuthRoutes from "@/modules/authorization";
import VirtualRoutes from '@/modules/virtual-sport';
import RaceRoutes from "@/modules/race";
import CasinoSlotRoutes from "@/modules/casino-slot";
import OthersRoutes from "@/modules/others";
import NotificationsRoutes from "@/modules/notification";

import Login from '@/modules/authorization/components/Login.vue';
import SignUp from '@/modules/authorization/components/SignUp.vue';
import ForgetPassword from '@/modules/authorization/components/ForgetPassword.vue';
import Rules from '@/shared/components/rules/Rules.vue'
import NotFound from "../shared/components/no-data/404.vue";

const routes = [
    {
        path: "/",
        component: Home,
        name: "Home",

    },
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/register",
        name: "SignUp",
        component: SignUp
    },
    {
        path: "/forgot-password",
        name: "ForgetPassword",
        component: ForgetPassword
    },
    {
        path: "/rules",
        name: "Rules",
        component: Rules
    },
    {
        path: "/maintenance",
        name: "Maintenance",
        component: MaintenanceComponent
      },

    SportsRoutes,
    AccountRoutes,
    GamesRoutes,
    matkaRoutes,
    walletRoutes,
    BonusRoutes,
    ReferAndEarnRoutes,
    BetsRoutes,
    AuthRoutes,
    VirtualRoutes,
    RaceRoutes,
    CasinoSlotRoutes,
    OthersRoutes,
    CricketFightRoutes, 
    NotificationsRoutes,
    { 
        path: '/:pathMatch(.*)*',
        name: 'NotFound', 
        component: NotFound 
    }, 

];

export default createRouter({
    history: createWebHistory(),
    routes
});