const state = {
    siteSettings: null,
    gamesData: null,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    isClickBetOn: false,
    favouriteGames:null,
    globalLoader:false,
    depositAmount: 0,
    captchaData: null,
    notification:0,
    languagesData:null,
    siteVersion:null,
    underMaintenance:null,
    balExpDisplay:[
        'balance',
        'exposure'
    ],
    recommendedMatch: {},
    languageSelected:null,
    sports_list:null,
    successMsg: null,
    errorMsg: null,
    walletHistory: null,
    loggedInUserIds: []
};
const getters = {
    siteSettings: state => state?.siteSettings,
    languagesData: state => state?.languagesData,
    languageSelected: state => state?.languageSelected,
    gamesData: state => state?.gamesData,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    isClickBetOn: state => state?.isClickBetOn,
    clickBetActive: state => state?.clickBetValue?.find(item => item.isSelected) ?? false,
    favouriteGames:state => state?.favouriteGames,
    globalLoader:state => state?.globalLoader,
    depositAmount:state => state?.depositAmount,
    captchaData: state => state?.captchaData,
    getNotification: state => state?.notification,
    getBalExpDisplay: state => state?.balExpDisplay,
    getRecommendedMatch: state => state?.recommendedMatch,
    siteVersion: state => state?.siteVersion,
    underMaintenance: state => state?.underMaintenance,
    sports_list: state => state?.sports_list,
    successMsg: state => state?.successMsg,
    errorMsg: state => state?.errorMsg,
    getSportImage: state => (sportId) => {
        const imageMap = {
            4: require("@/assets/images/sports-cricket.png"),
                1: require("@/assets/images/sports-soccer.svg"),
                2: require("@/assets/images/sports-tennis.svg"),
                1477: require("@/assets/images/sports-rugby-league.svg"),
                6: require("@/assets/images/sports-boxing.svg"),
                5: require("@/assets/images/sports-rugby-union.svg"),
                3: require("@/assets/images/sports-golf.svg"),
                27454571: require("@/assets/images/sports-esports.svg"),
                2152880: require("@/assets/images/sports-gaelic-football.svg"),
                998917: require("@/assets/images/sports-volleyball.svg"),
                7524: require("@/assets/images/sports-ice-hockey.svg"),
                7522: require("@/assets/images/sports-basketball.svg"),
                7511: require("@/assets/images/sports-baseball.svg"),
                6423: require("@/assets/images/sports-american-football.svg"),
                6422: require("@/assets/images/sports-snooker.svg"),
                11: require("@/assets/images/sports-cycling.svg"),
                8: require("@/assets/images/sports-motor-sport.svg"),
                2378961: require("@/assets/images/sports-politics.svg"),
                468328: require("@/assets/images/sports-handball.svg"),
                61420: require("@/assets/images/sports-australian-rules.svg"),
                3503: require("@/assets/images/sports-darts.svg"),
                10: require("@/assets/images/sports-special-bets.svg"),
                26420387: require("@/assets/images/sports-mixed-martial-arts.svg"),
                4339: require("@/assets/images/sports-greyhound-racing.svg"),
                7: require("@/assets/images/sports-horse-racing.svg"),
        };
        return imageMap[sportId] || require("@/assets/images/sports-no.svg");
    },
    walletHistory: state => state?.walletHistory,
    isUserLoggedIn: (state) => (userId) => state.loggedInUserIds.includes(userId)
};
const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },
    setLanguageSelected({ commit }, languageSelected) {
        commit('setLanguageSelected', languageSelected);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setIsClickBetOn({ commit }, isClickBetOn) {
        commit('setIsClickBetOn', isClickBetOn);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    setDepositAmount({ commit }, depositAmount) {
        commit('setDepositAmount', depositAmount);
    },
    setCaptchaData({ commit }, captchaData) {
        commit('setCaptchaData', captchaData);
    },
    setNotification({ commit }, notification) {
        commit('setNotification', notification);
    },
    setBalExpDisplay({ commit }, balExpDisplay) {
        commit('setBalExpDisplay', balExpDisplay);
    },
    setRecommendedMatch({ commit }, RecommendedMatch) {
        commit('setRecommendedMatch', RecommendedMatch);
    },
    setSiteVersion({ commit }, siteVersion) {
        commit('setSiteVersion', siteVersion);
    },
    setUnderMaintenance({ commit }, underMaintenance) {
        commit('setUnderMaintenance', underMaintenance);
    },
    setSportsList({ commit }, sports_list) {
        commit('setSportsList', sports_list);
    },
    showSuccessModal({ commit }, message) {
        commit('showSuccessModal', message);
        setTimeout(() => {
            commit('showSuccessModal', null);
        }, 2000);
    },
    showErrorModal({ commit }, message) {
        commit('showErrorModal', message);
        setTimeout(() => {
            commit('showErrorModal', null);
        }, 2000);
    },
    setWalletHistoryData({ commit }, walletHistory) {
        commit('setWalletHistoryData', walletHistory);
    },
    setloggedInUserIds({ commit }, userId) {
        commit('setloggedInUserIds', userId);
    },
};
const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings;
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
    setLanguageSelected(state, languageSelected) {
        state.languageSelected = languageSelected;
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setIsClickBetOn(state, isClickBetOn) {
        state.isClickBetOn = isClickBetOn;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    setDepositAmount(state, depositAmount){
        state.depositAmount =  depositAmount
    },
    setCaptchaData(state, captchaData) {
        state.captchaData = captchaData;
    },
    setNotification(state) {
        state.notification = state.notification+1;
    },
    setBalExpDisplay(state, balExpDisplay) {
        state.balExpDisplay = balExpDisplay;
    },
    setRecommendedMatch(state, RecommendedMatch) {
        state.recommendedMatch = RecommendedMatch;
    },
    setSiteVersion(state, siteVersion) {
        state.siteVersion = siteVersion;
    },
    setUnderMaintenance(state, underMaintenance) {
        state.underMaintenance = underMaintenance;
    },
    setSportsList(state, sports_list) {
        state.sports_list = sports_list;
    },
    showSuccessModal(state, message) {
        state.successMsg = message
    },
    showErrorModal(state, message) {
        state.errorMsg = message
    },
    setWalletHistoryData(state, walletHistory){
        state.walletHistory =  walletHistory
    },
    setloggedInUserIds(state, userId){
        if (!state.loggedInUserIds.includes(userId)) {
            state.loggedInUserIds.push(userId);
          }
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};