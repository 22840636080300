<template>
  <section class="not-found-wrapper">
        <div class="container">
        <div class="not-found-sec">
        <div class="row align-items-center">
        <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="found-img"><img src="@/assets/images/not-found.jpg" alt="error"></div>
        </div><div class="col-lg-6 col-md-6 col-sm-12">
        <div class="found-heading"><h1>404</h1><p>Not Found</p>
        <router-link to="/" class="btn_submit_cl">Go To Homepage</router-link>
        </div></div></div></div></div>
    </section>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
}

.found-img img {
  width:320px;
}

.found-img{
text-align:center;
}

.found-heading {
  text-align: center;
}
.not-found-wrapper{
background-color:#fff;
height:100vh;
margin-bottom:-52px;
}

.found-heading h1 {
  color: #000;
  font-size: 80px;
  font-weight: 800;
  line-height:40px;
  margin-bottom: .5rem;
}

.found-heading p {
  color: #707070;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.6
}

.found-heading .btn_submit_cl {
  margin-top: 10px;
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  background: linear-gradient(-180deg, #315195 0%, #14213d 100%);
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
}

.align-items-center {
  align-items: center!important;
  background-color:#fff;
  flex-direction: column;
}
</style>
