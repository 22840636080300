<template>
    <section class="login-now" loding="lazy">
        <div class="wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="loginInner1">
                            <div class="log-logo">
                                <img :src="$siteSettings?.data?.aws_url + $siteSettings?.data?.storage_path.domain_image + $siteSettings?.data?.logo" alt="logo" class="logo-login">
                            </div>
                            <div class="featured-box-login">
                                <!-- <h1>{{ $t('forgot-password','Forgot Password?')}} <i class="fas fa-hand-point-down"></i></h1> -->
                                <!-- <div role="alert" class="p-2 alert alert-danger" v-if="showValidationError && errorList.length">
                                    <ul>
                                        <li class="py-1" v-for="(error, index) in errorList" :key="index">&#x2022; {{ error }}</li>
                                    </ul>
                                </div> -->
                                <form class="form-control-head">
                                    <div class="form-group">
                                        <div class="get-mob">
                                            <div class="row">
                                                <div class="col-2">
                                                    <div class="col-auto">
                                                        <select v-model="form.code" class="form-select"
                                                            aria-label="Default select example" :disabled="timerRunning">
                                                            <option :value="allowedCountry.phonecode"
                                                                v-for="(allowedCountry, countryIndex) in $siteSettings?.data?.country_code_list"
                                                                :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-10">
                                                    <div class="col-auto">
                                                        <input type="tel" class="form-control add" id="autoSizingInput" maxlength="10" @input="form.mobileNo = $helper.sanitizeNumber(form.mobileNo, 9999999999);validate()"
                                                        :placeholder="$t('enter-mobile-number','Enter Mobile Number')+'*'" v-model="form.mobileNo" :disabled="resendOtp && timerRunning"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mak-gin mo-number-right get_otp">
                                                <button class="btn cmn-bdr-btn otp-btn" @click.prevent="sendOtpCall()" :disabled="timerRunning">{{ timerRunning ? formatTime(minutes) + ":" + formatTime(seconds) : resendOtp ? $t('resend-otp',"Resend OTP") : $t('get-otp',"Get OTP") }}</button>
                                            </div>
                                        </div>
                                        <div v-if="init.mobile && checkValidation?.mobile?.length > 0" class="mt-2">
                                            <span v-for="(err, index) in checkValidation?.mobile" :key="index">
                                                <p class="wrong-message">{{ $t(err.lang, err.error) }}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="init.mobile && form.mobileNo.length == 10 && !isOtpApiCalled" class="mt-2">
                                        <p class="wrong-message">{{ $t('please-click-on-send-otp','Please Click on Get OTP')}}.</p>
                                    </div>
                                    <div class="otp-form-group">
                                        <div class="get-mob for" v-if="isOtpApiCalled">
                                            <input type="tel" class="form-control" id="inputPassword2" :placeholder="$t('enter-otp','Enter OTP')+'*'" v-model="form.otp" maxlength="6" @input="form.otp = $helper.sanitizeNumber(form.otp, 999999);validate()" required/>
                                            <div class="inp-img"><img src="@/assets/images/key-icon.webp" alt="" /></div>
                                        </div>
                                        <div v-if="isOtpApiCalled && init.otp && checkValidation?.otp?.length > 0" class="mt-2">
                                            <span v-for="(err, index) in checkValidation?.otp" :key="index">
                                                <p class="wrong-message">{{ $t(err.lang, err.error) }}</p>
                                            </span>
                                        </div>
                                        <!-- <div v-if="resendOtp">
                                            <span id="resend_time" v-if="timerRunning" class="otp-countdown-text text-danger">
                                                {{ $t('resend-in',"Resend OTP in")}} {{ formatTime(minutes) + ':' + formatTime(seconds) }}
                                                <span id="timer"></span></span>
                                            <span id="resend_button" class="otp-countdown-text" v-else>
                                                <a href="Javascript:void(0);" class="text-success resend-btn" type="button" @click.prevent="optCallWithValidationCheck()">
                                                    {{ $t('send-sms-again',"SEND SMS AGAIN")}}
                                                </a>
                                            </span>
                                        </div> -->
                                    </div>
                                    
                                    <div class="form-group">
                                        <div class="get-mob for left_password_field">
                                            <input :type="passwordFieldType" maxlength="20" class="form-control" id="inputPassword4" :placeholder="$t('enter-password','Enter Password')+'*'" v-model="form.password"  @input="form.password = $helper.sanitizeDetails('password', form.password, 20);validate();"/>
                                            <div class="key-img"><img src="@/assets/images/key-icon.svg" alt="" /></div>
                                            <div class="inp-img" @click="togglePasswordVisibility('passwordFieldType')">
                                                <img :src="passwordFieldType === 'password' ? require('@/assets/images/passwordhideicon.webp') : require('@/assets/images/passwordviewicon.webp')" alt=""/>
                                                <!-- <img v-else src="@/assets/images/passwordviewicon.webp" alt=""/> -->
                                            </div>
                                        </div>
                                        <div v-if="isOtpApiCalled && init.password && checkValidation?.password?.length > 0" class="mt-2">
                                            <span v-for="(err, index) in checkValidation?.password" :key="index">
                                                <p class="wrong-message">{{ $t(err.lang, err.error) }}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="get-mob for left_password_field">
                                            <input :type="confPasswordFieldType" maxlength="20" class="form-control" id="inputPassword5" :placeholder="$t('enter-confirm-password','Enter Confirm Password')+'*'" v-model="form.confirmPassword"  @input="form.confirmPassword = $helper.sanitizeDetails('password', form.confirmPassword, 20);validate()"/>
                                            <div class="key-img"><img src="@/assets/images/key-icon.svg" alt="" /></div>
                                            <div class="inp-img" @click="togglePasswordVisibility('confPasswordFieldType')">
                                                <img :src="confPasswordFieldType === 'password' ? require('@/assets/images/passwordhideicon.webp') : require('@/assets/images/passwordviewicon.webp')" alt=""/>
                                                <!-- <img v-else src="@/assets/images/passwordviewicon.webp" alt=""/> -->
                                            </div>
                                        </div>
                                        <div v-if="isOtpApiCalled && init.confirmPassword && checkValidation?.confirmPassword?.length > 0" class="mt-2">
                                            <span v-for="(err, index) in checkValidation?.confirmPassword" :key="index">
                                                <p class="wrong-message">{{ $t(err.lang, err.error) }}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="button" class="btn btn-submit btn-login" @click="forgetPasswordCall()">
                                            {{ $t('update-password',"Update Password")}}
                                            <i class="ml-2 fas fa-sign-in-alt"></i>
                                            <span v-if="loadingUpdate"> 
                                                <span class="load-inner"><i class="fa fa-spinner fa-spin mt-0"></i></span>
                                            </span>
                                        </button>
                                    </div>

                                    <div class="for-pass">
                                        <p class="login-with-acc u-code">{{ $t('remember-your-password','Remember your password')}}?&nbsp;
                                            <router-link v-if="isRootUrl" :to="{ name: 'Login' }" @click="refreshData">
                                                {{ $t('log-in','Login')}}
                                            </router-link>
                                            <a v-else data-bs-toggle="modal" ref="openLoginModal" @click="refreshData" data-bs-target="#loginModal">
                                                {{ $t('log-in','Login')}}
                                            </a>
                                        </p>
                                    </div>

                                    <p class="whats-with-acc u-code">
                                        <router-link v-if="isRootUrl" :to="{ name: 'SignUp' }" @click="refreshData"> 
                                            {{ $t('create-new-account','Create New Account')}}
                                        </router-link>
                                        <a v-else data-bs-toggle="modal" @click="refreshData" data-bs-target="#signupModal">
                                            {{ $t('create-new-account','Create New Account')}}
                                        </a>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import commonAuth from '../services/commonAuth';
import validate from '../services/authValidation';

export default {
    name: "ForgetPassword",
    extends: commonAuth,
    data() {
        return {
            form: {
                code: this.$siteSettings.data && this.$siteSettings.data.country_code ? this.$siteSettings.data.country_code : 91,
                mobileNo: "",
                otp: [],
                password: "",
                confirmPassword: "",
            },
            loadingUpdate: false,
            validationErrors : null,
        };
    },
    computed: {
        isRootUrl() {
            return this.$route.path === '/login' || this.$route.path === '/forgot-password' || this.$route.path === '/register';
        },
        checkValidation() {
			return this.validationErrors;
		}
    },
    methods: {
        togglePasswordVisibility(fieldName) {
            this[fieldName] = this[fieldName] === 'password' ? 'text' : 'password';            
        },
        forgetPasswordCall() {
            if (this.loadingUpdate) return;
            this.toggleInitPhn(true);
            if(this.isOtpApiCalled) this.toggleInit(true);
            if(Object.keys(this.validate()).length != 0) return;
            if (!this.isOtpApiCalled){ 
                return
            }
            this.forgotServiceCall();
        },
        async forgotServiceCall() {
            this.loadingUpdate = true;         
            let data = {
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "confirm_password": this.form.confirmPassword,
                "otp": this.getOtp(),
                "cncode": this.form.code,
            };
            this.$apiCall('post','FORGOT_PASSWORD', data).then(response => {
                this.loadingUpdate = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.$errorToast(response.data.debug[0]);
                        }
                        else {
                            this.$successToast(response.data.message);
                            this.refreshData();
                            if(this.isRootUrl){
                                this.$router.push({name: 'Login'})
                            }
                            else{
                                this.$refs.openLoginModal.click();
                            }
                        }
                    }
                }
            }).catch(error => {
                this.loadingUpdate = false;
                if (error) {
                    this.$errorToast(error[0]);
                }
            });
        },
        validate(){
            this.validationErrors = validate.areFieldsValid(
                'forgot-password',
				this.form.mobileNo, 
				this.form.otp, 
				this.form.password,
				this.form.confirmPassword,
            );
            return this.validationErrors;                                                                                                    
        },
        validateMobileOnly(){
            this.validationErrors = validate.areFieldsValid(
                'only-mobile',
				this.form.mobileNo
            );
            return this.validationErrors;
        },
        toggleInitPhn(bool){
            this.init={
                mobile:bool
            }
        },
        toggleInit(bool){
            this.init={
                mobile:bool,
                otp:bool,
                confirmPassword:bool,
                password:bool
            }
        },
        optCallWithValidationCheck(){
            this.isOtpApiCalled = true;
            if(this.form.mobileNo.length!=10){
                this.init.mobile = true;
            }
            else{
                this.init.mobile = false
            }
            if(Object.keys(this.validateMobileOnly()).includes('mobile')) return;
            this.sendOtpServiceCall();
        },
    }
};
</script>