const SportsModule = () => import('./views/Module.vue'); 
const Sports = () => import('./views/Sports.vue');
const SportsEventDetail = () => import('./views/SportEventDetail.vue');
const SportsBook = () => import(/* webpackChunkName: "sport-book" */ './views/SportsBook.vue');

const SportsRoutes =   {
    path: '/',
    component: SportsModule,
    children: [
      {
        path: '/sport-event-detail/:event_id',
        name: 'SportsEventDetail',
        component: SportsEventDetail
      },
      {
        path: 'sports/:type?/:id?/:head_sport?',
        name: 'Sports',
        component: Sports
      },
      {
        path: 'sports-book',
        name: 'sports-book',
        component: SportsBook
      }
    ],
  }

export default SportsRoutes;