const AuthModule = () => import(/* webpackChunkName: "account-module" */ './views/Module.vue');
const  ChangePassword = () => import(/* webpackChunkName: "account-module" */ '../authorization/components/ChangePassword.vue');

const AuthRoutes =  {
    path: '/',
    component: AuthModule,
    children: [
     
        {
            path: '/change-password',
            name: 'change-password',
            component: ChangePassword,
          }
    ],
  }

export default AuthRoutes;